
const AccountDetailsTable = () => import("@/components/account/AccountDetailsTable.vue").then(m => m.default || m)
import Vue from "vue"
export default Vue.extend({
	components: { AccountDetailsTable },
	name: "AccountDetailsDialog",
	props: {
		details: {
			type: Boolean,
		},
	},
	computed:{

	},
	methods: {
		closeLog(): void {	
			this.$emit("close", null)			
		},
	},
})
